// JavaScript
// src/firebase.js
import { initializeApp } from "firebase/app"
import { getFirestore} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCZDrJr0LY4MiPq4EW9gDB8s78HAz4anBk",
    authDomain: "advent-calendar-f6ce9.firebaseapp.com",
    projectId: "advent-calendar-f6ce9",
    storageBucket: "advent-calendar-f6ce9.appspot.com",
    messagingSenderId: "1098782724052",
    appId: "1:1098782724052:web:d67bb8ba549c3388db9041",
    measurementId: "G-J4BX4XBGMK"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export {db}