import {useState, useEffect, useCallback} from 'react'
import '../styles.css';
import '../book.css';
import {motion, MotionConfig } from "framer-motion";
import { gsap } from "gsap";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward} from 'react-icons/io';

import Page from './Page';
import useSound from 'use-sound'
import pageTurnForward from '../audio/page_forward.wav';
import pageTurnBack from '../audio/page_back.wav';
import bookPick from '../audio/Book_Pick.wav';
import bookPut from '../audio/Book_Put.wav';

function Book(content, minimizeBook) {
  const [bookState, setBookState] = useState("inactive");
  const [bookPositionState, setBookPositionState] = useState("firstPage");
  const [exitButtonState, setExitButtonState] = useState("inactive");
  const [playSoundForward] = useSound(pageTurnForward);
  const [playSoundBack] = useSound(pageTurnBack);
  const [playSoundBookPut] = useSound(bookPut);
  const [playSoundBookPick] = useSound(bookPick);
  const [bookPageIndex, setBookPageIndex] = useState(0);
  const [state, setState] = useState("state");

  const loadDataOnlyOnce = useCallback(() => {
    showBook();
  }, [state]);

  useEffect(() => {
      loadDataOnlyOnce();
  }, [loadDataOnlyOnce]);

  useEffect(() => {
    var pages = document.getElementsByClassName('page');
    unhideVideoPlayer();
    for(var i = 0; i < pages.length; i++)
    {
      var page = pages[i];
      
      if (i % 2 === 0)
        {
          page.style.zIndex = (pages.length - i);
          page.style.opacity = 0.99;
        }
        else
        {
          page.style.opacity = 0.99;
        }
    }
    for(var i = 0; i < pages.length; i++)
    {
      pages[i].pageNum = i + 1;
    }
    if(bookPageIndex===pages.length)
    {
        setBookPositionState("lastPage");
        //document.getElementsByClassName("video-player").style.webkitTransform = "translate3d(0,0,1px)";
    }
    else if(bookPageIndex>0 && bookPageIndex<pages.length)
    {
     
        setBookPositionState("middlePage");
      
    }
    else
    {
      setBookPositionState("firstPage");
    }
  })
  const hideVideoPlayer = () =>{
    var videoPlayer = document.getElementsByClassName('video-player');
      //console.log(videoPlayer);
      videoPlayer[0].style.height = 0;
      videoPlayer[0].style.width = 0;
  }
  const unhideVideoPlayer = () =>{
    var videoPlayer = document.getElementsByClassName('video-player');
    videoPlayer[0].style.height = "100%";
    videoPlayer[0].style.width = "85%";
  }
  const goForwardInBook = () =>
  {
    var pages = document.getElementsByClassName('page');
    /*for(var i = 0; i < pages.length; i++)
    {
      var page = pages[i];
      if (i % 2 === 0)
        {
          page.style.zIndex = (pages.length - i);
        }
    }*/
    if(bookPageIndex=== pages.length)
    {
      hideBook();
      return;
    }
        if (pages[bookPageIndex].pageNum % 2 === 0)
          {
            pages[bookPageIndex].classList.remove('flipped');
            pages[bookPageIndex].previousElementSibling.classList.remove('flipped');
          }
        else
          {
            if(pages[bookPageIndex]!=null)
            {
              pages[bookPageIndex].classList.add('flipped');
              pages[bookPageIndex].nextElementSibling.classList.add('flipped');
            }
            playSoundForward()
          }
    if(pages.length>=bookPageIndex+2)
    {
      
      setBookPageIndex(bookPageIndex+2);
    }
  }
  const goBackInBook = () =>
  {
    var pages = document.getElementsByClassName('page');
    if(bookPageIndex-1>0)
    {
      if (pages[bookPageIndex-1].pageNum % 2 === 0)
      {
        pages[bookPageIndex-1].classList.remove('flipped');
        pages[bookPageIndex-1].previousElementSibling.classList.remove('flipped');
      }
      else
      {
        pages[bookPageIndex-1].classList.add('flipped');
        pages[bookPageIndex-1].nextElementSibling.classList.add('flipped');
      }
      setBookPageIndex(bookPageIndex-2);
      playSoundBack()
    }
  }
  const showBook = () => {
    setBookState("active");
    setExitButtonState("active");
    setBookPageIndex(0);
    setTimeout(playSoundBookPick,700)
  }
  const hideBook = () => {
    playSoundBookPut();
    setBookState("inactive");
    setExitButtonState("inactive");
    content.minimizeBook();
  }
  const variants = {
    active: {
        opacity:1,
        transition: {delay:0.5,duration: 0.5,  ease: "easeIn"}
    },
    inactive: {
      opacity: 0,
      transition: { duration: 0.2,   default: { ease: "easeIn" } },
    }
  }
  const exitButtonVariants = {
    active: {
        opacity:1,
        x: 0,
        transition: {delay:0.5,duration: 0.5}
    },
    inactive: {
      opacity: 0,
      x: 100,
      transition: { duration: 0.2 },
    }
  }
  const leftControlButtonVariants = {
    active: {
        opacity:1,
        x: 0,
        transition: {delay:0.5,duration: 0.5}
    },
    inactive: {
      opacity: 0,
      x: -100,
      transition: { duration: 0.2 },
    }
  }
  const rightControlButtonVariants = {
    active: {
        opacity:1,
        x: 0,
        transition: {delay:0.5,duration: 0.5}
    },
    inactive: {
      opacity: 0,
      x: 100,
      transition: { duration: 0.2 },
    }
  }
  const bookPositionVariants = {
    lastPage: {
        x:"-25%",
        transition:{
          duration:0.5,
          ease: "linear"
        }
    },
    middlePage: {
      x:"-50%",
      transition:{
        duration:0.5,
        ease: "linear"
      }
    },
    firstPage: {
        x:"-75%",
        transition:{
          duration:0.5,
          ease: "linear"
        }
    }
  }
  return (
    <div className='bookerino'>
                                  <motion.div
                                        variants={exitButtonVariants} animate={exitButtonState}
                                        onClick={()=>hideBook()}
                                        className='minimize-button'><IoMdClose style={{ height: '100%', width: '100%' , color: "white" }}></IoMdClose>
                                  </motion.div>
                                  <motion.div
                                          variants={variants} animate={bookState}
                                          className="book">
                                        <motion.div   variants={bookPositionVariants} animate={bookPositionState} className="pages">
                                        <div className="page" id="front-cover"></div>
                                          {
                                            content.content.map((contentPage) =>
                                            {
                                              const pageContent = contentPage.content;
                                              const pageIsPicture = contentPage.isPicture;
                                              const pageIsVideo = contentPage.isVideo;
                                              return <Page key={contentPage.id} pageContent={pageContent} pageIsPicture={pageIsPicture} pageIsVideo={pageIsVideo}></Page>
                                            })
                                          }
                                          <div className="page" id="back-cover"></div>
                                        </motion.div>
                                  </motion.div>
                                  <motion.div variants={leftControlButtonVariants} animate={exitButtonState} className='book-control-left' onClick={()=> goBackInBook()}><IoIosArrowBack style={{ height: '100%', width: '100%' , color: "white"}}></IoIosArrowBack></motion.div>
                                  <motion.div variants={rightControlButtonVariants} animate={exitButtonState} className='book-control-right' onClick={()=> goForwardInBook()}><IoIosArrowForward style={{ height: '100%', width: '100%' , color: "white" }}></IoIosArrowForward></motion.div>
                            </div>
  )
}
export default Book;