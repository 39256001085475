
import {useState, useEffect,useRef,forwardRef,useImperativeHandle} from 'react'
import GridLayout from "react-grid-layout";
import '../styles.css';
import Window from './Window';
import '../book.css';
import '../css/label.css'
import '../windowholder.css';
import {setDoc, doc, Timestamp} from 'firebase/firestore';
import {db} from '../firebase'
import { gsap } from "gsap";
import useSound from 'use-sound'
import backgroundAudio from '../audio/background.mp3';
import windowOpenAudio from '../audio/window_open.mp3';
import {IoMdVolumeOff, IoMdVolumeHigh} from 'react-icons/io';

const WindowHolder = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    initCursor()
    {
      createParticles();
      drawStar();
      drawCursor();
 
    },
    layoutReady(layout,windowsProgress,classroomID)
    {
      setClassroomID(classroomID);
      setWindowProgress(windowsProgress);
      setSetupFinished(true);
        for(var i = 1; i<layout.length+1; i++)
      {
        let obj2 = windowsProgress.find(n=> n.id ==i);
        let objIndex = layout.findIndex((obj => obj.i == i));
        layout[objIndex].wasOpened=obj2.wasOpened;
      }
      setWindowLayout(layout);
    }
  }));

  const [windowLayout, setWindowLayout] = useState(null);
  const [windowProgress, setWindowProgress] = useState(null);
  const [classroomID,setClassroomID] = useState(props.classroomTitle);
  const [setupFinished, setSetupFinished] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [backgroundVolume, setBackgroundVolume] = useState(0.1);
  const [dimensions, setDimensions] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  const [playSoundBackground, {sound}] = useSound(backgroundAudio,{volume:backgroundVolume});
  const [playWindowOpenSound, {windowOpenSound}] = useSound(windowOpenAudio,{volume:0.5});
  const cursorBusy = useRef(false);
 
  let mouseX = 0;
  let mouseY = 0;
  const ball = document.querySelector(".sparkle");
  let cursorFlicker = gsap.timeline({
    repeat:-1,
    defaults:{
      ease: "none",
      duration:0.5
    }})
  .to(".sparkle", {scale:2,xPercent:-50,yPercent:-50})
  .to(".sparkle", {scale:1,xPercent:0,yPercent:0})
  cursorFlicker.pause();
  
  let mouse = { x: 0, y: 0 };
  const xSet = gsap.quickSetter(ball, "x", "px");
  const ySet = gsap.quickSetter(ball, "y", "px");
  const mainSVG= document.querySelector('#mainSVG');
  window.addEventListener("mousemove", e => {    
    mouseX = e.x; 
    mouseY = e.y;
  });
  var 
     select = function(s) {
      return document.querySelector(s);
    },
    showParticle = true,
    particleColorArray = ['#FFFFE6', '#FFFFCC', '#FFFFB3','#FFFF99','#FFFF80', '#FFFF66', '#FFFF4D', '#FFFF33', '#FFFF19'],
    particleTypeArray = ['#star','#circ','#cross','#heart'],
    particlePool = [],
    particleCount = 0,
    numParticles = 151

  useEffect(()=>
  {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })}

    if(sound!=null)
    {
      sound.loop(true);
    }
     window.addEventListener('click', playBackgroundAudio)
     window.addEventListener('resize', handleResize)

     return _ => {
       window.removeEventListener('resize', handleResize)
     }
  })
  
  function playBackgroundAudio(){

    window.removeEventListener('click', playBackgroundAudio);
    if(!isPlaying)
    {
      setIsPlaying(true);
      playSoundBackground();
    }
  }
  function toggleSound()
  {
    if(!isMuted)
    {
      setIsMuted(true);
      setBackgroundVolume(0);
    }
    else{
      setIsMuted(false);
      setBackgroundVolume(0.1);
    }
  }
  function createParticles() {
    
    var i = numParticles, p, particleTl, step = 1, pos;
    while (--i > -1) {
      
      if(mainSVG!=null)
      {
        p = select(particleTypeArray[i%particleTypeArray.length]).cloneNode(true);
        mainSVG.appendChild(p);
        p.setAttribute('fill', particleColorArray[i % particleColorArray.length]);
        p.setAttribute('className', "particle");   
        particlePool.push(p);
        //hide them initially
        gsap.set(p, {
                      x:-100, 
                      y:-100,
        transformOrigin:'50% 50%'
                     })
      }
      
    }
  }
  
  var getScale = gsap.utils.random(0.5, 3, 0.001, true);
  
  function playParticle(p){
    
    if(!showParticle){return};
    var p = particlePool[particleCount]
    
    if(p!==undefined)
    {
      var currentX =  gsap.getProperty('.sparkle', 'x');
      var currentY =  gsap.getProperty('.sparkle', 'y')
    gsap.set(p, {
     x: gsap.getProperty('.sparkle', 'x'),
     y: gsap.getProperty('.sparkle', 'y'),
     scale:getScale()
      }
      );
      
    var tl = gsap.timeline();
    tl.to(p, {
      duration: gsap.utils.random(0.61,1.5),
        scale:0,
        x: currentX+gsap.utils.random(-25,25),
        y: currentY +gsap.utils.random(-25,25),
        rotation:gsap.utils.random(-123,360),
        ease: 'power1',
      });
    }
    
    
  
    particleCount++;
    particleCount = (particleCount >=numParticles) ? 0 : particleCount
    
  }
  
  function drawStar(){
    gsap.set('#mainSVG', {
      visibility: 'visible'
    })
    gsap.ticker.add(playParticle);
  }
  
  function drawCursor(){
    cursorBusy.current = false;
    window.addEventListener("mousemove", e => {   
      if(e.x!==0 && e.y!==0)
      {
        mouse.x = e.x;
        mouse.y = e.y; 
      }
      gsap.ticker.add(setMousePosition);
    });
    
    
  }
  function setMousePosition()
  {
    if(cursorBusy.current)
    {
      return;
    }

    xSet(mouse.x);
    ySet(mouse.y);
  }

  function drawCursorOnRect(el){
    mouseX = gsap.quickSetter(ball, "x", "px");
    mouseY = gsap.quickSetter(ball, "y", "px");
    console.log("mouse X: " + mouseX)
    console.log("mouse Y: " + mouseX)
    gsap.ticker.remove(setMousePosition);
    cursorBusy.current = true;
    var rect = el.getBoundingClientRect();
    handleMouseOut();
    gsap.timeline({
      repeat: 0,
      defaults: {
        ease: "none",
        
      }
    })
    .to(ball, { x:rect.x,y:rect.y, duration: 0.4, ease:"easeIn"})
    .to(ball, { x: rect.right ,duration: 1})
    .to(ball, { y: rect.bottom, duration: 1})
    .to(ball, { x: rect.left, duration: 1 })
    .to(ball, { y: rect.top, duration: 1, onComplete: returnMouseToOriginalPosition})
    setTimeout(()=>playWindowOpenSound(),400);
  }
  function returnMouseToOriginalPosition(){ 
    console.log("mouse X: " + mouseX)
    console.log("mouse Y: " + mouseY)
    gsap.timeline({
      repeat: 0,
      defaults: {
        ease: "none",
      }
    })
    .to(ball, { y: mouseY, x: mouseX, duration: 0.3,onComplete: drawCursor });
  }
  
  function setMouseFailToOpen()
  {
    //mouseX = gsap.getProperty(ball, "x", "px");
    //mouseY = gsap.getProperty(ball, "y", "px");
    console.log("mouse X: " + mouseX)
    console.log("mouse Y: " + mouseY)
    cursorBusy.current = true;
    handleMouseOut();
    gsap.ticker.remove(setMousePosition);
    
    gsap.timeline({
      repeat: 0,
      defaults: {
        ease: "none",
      }
    })
    .to(ball, { x: mouseX+50, duration: 0.1, ease:"easeIn"})
    .to(ball, { x: mouseX-50 ,duration: 0.1})
    .to(ball, { x: mouseX+50, duration: 0.1})
    .to(ball, { x: mouseX, duration: 0.1,onComplete: returnMouseToOriginalPosition} )
  }
  
  function attemptOpenWindow(openWindow, windowNumber)
  {
    if(!cursorBusy.current)
    {
      openWindow();
      /*var today = new Date((Timestamp.now().seconds*1000));
      var day = today.getDate();
      var month = today.getMonth()+1;
      if(windowNumber>day)
      {
        setMouseFailToOpen();
      }
      else
      {
        callback();
      }*/
    }
    else
    {
      console.log("Cursor is busy, cant open a new window");
    }
  }
  const lowerVolume = () => {
    if(isMuted)
    return;

    setBackgroundVolume(0.01);
    
  }
  const increaseVolume = () => {
    if(isMuted)
    return;

    setBackgroundVolume(0.1);
  }

  const saveOpenedWindow = async (windowID) => {
      await setDoc(doc(db,`classrooms/${props.classroomID}/progress/${windowID}`), {
        wasOpened : true,
      });
  }
  const handleMouseOver = (windowNumber) =>{
    if(cursorBusy.current)
    return;
    cursorFlicker.resume();
    /*var today = new Date((Timestamp.now().seconds*1000));
      var day = today.getDate();
      var month = today.getMonth()+1;
    if(windowNumber<=day)
    {
    
    }*/
    
  }
  const handleMouseOut = ()=>{
    cursorFlicker.pause(0);
  }
    return (

      
        <div  className='window-parent'>
          {isMuted && <div onClick={()=>toggleSound()} className='sound-button'>
                              <IoMdVolumeOff style={{ top: "20px",right:"20px",position: "fixed",height: '75px', width: '75px' , color: "white", zIndex:4}}></IoMdVolumeOff>
                                </div>}
                              {!isMuted && <div onClick={()=>toggleSound()}className='sound-button'>
                              <IoMdVolumeHigh style={{ top: "20px",right:"20px",position: "fixed",height: '75px', width: '75px' , color: "white", zIndex:4}}></IoMdVolumeHigh>
                                </div>}
         {windowLayout!=null &&
        <GridLayout
        className='gridLayout'
        allowOverlap = {true}
        layout={windowLayout}
        cols={100}
        items = {24}
        autoSize = {false}
        style ={{padding :"0px" }}
        useCSSTransforms = {false}
        width={document.documentElement.clientWidth}
        rowHeight= {document.documentElement.clientWidth/(document.documentElement.clientWidth/document.documentElement.clientHeight*56)}
        margin= {[0,0]}
        containerPadding= {[0, 0]}
        >
        {
          
          windowLayout.map((window) =>
          {
            return <div className='window' key={window.i}>
              <Window 

              
              isOpen = {window.wasOpened}
              id = {window.i}
              winID = {window.i}
              content = {window.content}
              saveWindowProgress = {saveOpenedWindow}
              onMouseHover= {handleMouseOver}
              onMouseHoverStop = {handleMouseOut}
              drawWindow = {drawCursorOnRect}
              attemptOpenWindow= {attemptOpenWindow}
              lowerVolume = {lowerVolume}
              increaseVolume = {increaseVolume}
            ></Window></div>
          })};
       
      </GridLayout>}
      <div className="labels">
                    <div className="label-container">
                      <div className="label">
                          <div className="label-side label-2-side">
                              <div className="label-text label-2-text">
                                <h3>{("Til: " + props.classroomTitle)}</h3>
                                  
                                  <div className="rule-diagonal"></div>
                              </div>
                          </div>
                          <div className="label-side label-2-side is-back">
                              <div className="label-text label-2-text">
                                <h3>Fra: En række folkekirkelige skoletjenester</h3>
                              
                                  <div className="rule-diagonal"></div>
                              </div>
                          </div>
                      </div>
                    </div>
      </div>

        
      </div>
        
    );
  
  
});
export default WindowHolder;