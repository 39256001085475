import {useState, useEffect, useRef, Suspense} from 'react'
import '../styles.css';
import '../book.css';
import ReactMarkdown from 'react-markdown'

function Page(pageContent, pageIsPicture , pageIsVideo) {
  let [ mdContent, setMDContent] = useState(``);

  const playerRef = useRef(null);

  useEffect(()=> {
   if(!pageContent.pageIsPicture && !pageContent.pageIsVideo)
    {
      //setMDContent(pageContent.pageContent);
      
        fetch(pageContent.pageContent)
        .then((res) => res.text())
        .then((md) => {
            setMDContent(md)
        })
    }
    
})

  if(pageContent.pageIsPicture)
    {
      return <div className="page" ><img className='illustration' src={pageContent.pageContent} /></div>
    }
    else if(pageContent.pageIsVideo)
    {
      return      <div className="page"><div className='video-player-parent'><iframe title={"VideoPlayer"} className="video-player" width="85%" height="100%" src={pageContent.pageContent} allowFullScreen = {true}></iframe></div></div>
    }
    else
    {
      return <div className="page"><ReactMarkdown children={mdContent}/></div>
    }
}

  
  
export default Page;

//<iframe title={"VideoPlayer"} className="video-player" width="85%" height="100%" src={pageContent.pageContent} allow="picture-in-picture" allowFullScreen></iframe>