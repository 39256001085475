
import {useState, useEffect, useRef} from 'react'
import '../styles.css';
import '../book.css';
import '../window.css'
import '../windowholder.css';
import {motion, MotionConfig } from "framer-motion";
import { gsap } from "gsap";
import Book from './Book';
import useSound from 'use-sound'
import bookPick from '../audio/Book_Pick.wav';

function Window({isOpen, drawWindow,attemptOpenWindow,saveWindowProgress,id,winID,content, onMouseHover,onMouseHoverStop,lowerVolume,increaseVolume}) {
  const [open, setOpen] = useState(isOpen)
  const [opening,setOpening] = useState(false);
  const [firstTimeOpen, setOpenFirstTime] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);
  const [bookVisibility, setBookVisibility] = useState(false);
  const [isMuted , setIsMuted] = useState(false);
  const [windowID,setWindowID] = useState(winID); 
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowX, setWindowX] = useState(0);
  const [windowY, setWindowY] = useState(0);
  const [windowAnimationState,setWindowAnimationState] = useState('');
  const [windowCardAnimationState,setWindowCardAnimationState] = useState('');
  const [windowTextAnimationState, setWindowTextAnimationState] = useState('');
  const [clipAnimationState,setClipAnimationState] = useState('');
  const [animationRunning,setAnimationRunning] = useState(false);
  const [windowBoundsCalculationComplete, setWindowBoundsCalculationComplete] = useState(false);
  const [playSoundBookPick] = useSound(bookPick);
  const ref = useRef(null);
  const openWindowRef = useRef();


  useEffect(() => {
    function handleResize() {
      setTimeout(()=>updateWindowBounds(),1500);
    }
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);
  const updateWindowBounds = () => {

    if(openWindowRef.current==null)
      return;
      var element = openWindowRef.current.getBoundingClientRect();
      setWindowHeight(element.height);
        setWindowWidth(element.width);
        setWindowX(element.x);
        setWindowY(element.y);
  }

  useEffect(() => {
      if(open)
      {
        if(!firstTimeOpen)
        {
          setClipAnimationState("visible");
          setWindowTextAnimationState("visible");
        }
        
      }
      if(opening && !animationRunning)
      {
        console.log("Opening and animation is not running!");
        onMouseHoverStop();
        //animateLineCursor()
        animateLine();
        
      }
      
      if(openWindowRef.current!=null )
      {
        if(windowBoundsCalculationComplete)
        return;

        var element = openWindowRef.current.getBoundingClientRect();
        setWindowHeight(element.height);
        setWindowWidth(element.width);
        setWindowX(element.x);
        setWindowY(element.y);
        setWindowBoundsCalculationComplete(true);
      }
      
     
  })


  
  const openWindow = () => {

    setOpening(true);
    drawWindow(ref.current);
    console.log("Opening a window for the first time: " + id);
    saveWindowProgress(id);
  }
  const openOpenedWindow = () =>{
    if(isFullscreen)
    return;
    drawWindow(openWindowRef.current);
    onMouseHoverStop();
        //animateLineCursor()
        animateLine();
  }

  const maximizeWindow = () => {
    if(animationRunning)
    return;
    if(isFullscreen)
    {
      return;
    }
    setClipAnimationState("hidden");
    setWindowTextAnimationState("hidden");
    setWindowAnimationState("visible");
    showBook();
    setTimeout(playSoundBookPick,100)
    setFullscreen(true)
  }
  const minimizeWindow = () => {
    setTimeout(hideBook, 1000);
    setClipAnimationState("visible");
    setWindowTextAnimationState("visible");
    setWindowAnimationState("hidden");
    setOpenFirstTime(false);
    setTimeout(removeFullscreen, 1000);
  }
  const showBook = () => {
    setBookVisibility(true);
    lowerVolume();
  }
  const hideBook = () => {
    setBookVisibility(false);
    increaseVolume();
    setTimeout(()=>updateWindowBounds(),1000);
  }
  const removeFullscreen = () => {
    setFullscreen(false)
    setWindowAnimationState("visible");
  }
  
 
  const animateLine = () => {
    setAnimationRunning(true);
    setWindowCardAnimationState("hidden");
   /* console.log("Animate line");
    const path = document.querySelector('#path'+winID);

    const length = path.getTotalLength();

    gsap.set(path, {
      strokeDashoffset: length,
      strokeDasharray: length
    });

    gsap.to(path, {
      strokeDashoffset: 0,
      duration: 4,
      delay: 0.5,
      ease: "none",
    });*/
    /*
      <div className='window-border-effect'>
                                      <svg  xmlns="http://www.w3.org/2000/svg" height={"100%"} width={"100%"} overflow="visible">
                                        <rect id={`path${windowID}`}  width="100%" height="100%" stroke="white" fill="transparent" strokeWidth="5"/>
                                      </svg>
                                  </div>*/
    /*
    <div className='window-border-effect'>
                                        <svg  xmlns="http://www.w3.org/2000/svg" height={"100%"} width={"100%"} overflow="visible">
                                                <rect id={`path${windowID}`} width="100%" height="100%" stroke="white" fill="transparent" strokeWidth="5"/>

                                      </svg>
                                    </div>*/
    setTimeout(fadeInWindowBackground,2000);
  }
  const fadeInWindowBackground = () =>
  {
    setOpenFirstTime(true);
    setOpen(true);
    //setWindowAnimationState('visible');
    setTimeout(fullStopAnimation,3000);
  }
  
  
  const fullStopAnimation = () =>{
    
    setOpening(false);
    setAnimationRunning(false);
    console.log("Full stop animation");
    maximizeWindow();
  }
  

  const windowAnimationVariants = {
    hidden: { opacity: 0, transition:{duration: 0.5, ease: "easeInOut" }},
    visible: { opacity: 1, transition:{duration: 1, ease: "easeInOut" }},
  }
  const clipAnimationVariants = {
    hidden: { opacity: 0, transition:{duration: 0.3, ease: "easeInOut" }},
    visible: { opacity: 1, transition:{duration: 1,delay:0.5, ease: "easeInOut" }},
  }
  const windowCardAnimationVariants = {
    visible: { opacity: 1},
    hidden: { opacity: 0, transition:{duration: 1, delay:0.5, ease: "easeInOut" }},
  }
  const windowTextAnimationVariants = {
    hidden: { opacity: 0, transition:{duration: 0.3, ease: "easeInOut" }},
    visible: { opacity: 1, transition:{duration: 1,delay:0.5, ease: "easeInOut" }},
  }
  return (
    <MotionConfig>
                      {open && 
                            <motion.div  
                             initial="visible"
                             animate={windowAnimationState}
                             variants={windowAnimationVariants}
                             ref={openWindowRef} onClick={()=>attemptOpenWindow(openOpenedWindow,winID)}className = {`container${isFullscreen ? "-maximized" : ""}`} layout>        
                                
                                {!isFullscreen && <div>
                                  <motion.div 
                                  initial="hidden"
                                  animate={clipAnimationState}
                                  variants={clipAnimationVariants}
                                  id={`clip`} style={{
                                     position:"absolute", 
                                     height:"100%", 
                                     width:"100%", 
                                     zIndex:"1",
                                     backgroundSize:`${document.documentElement.clientWidth}px ${document.documentElement.clientHeight}px`,
                                     backgroundPosition:`-${windowX}px -${windowY}px`,
                                     imageRendering:"crisp-edges"}}/>
                                  <motion.div 
                                  initial="hidden"
                                  animate={windowTextAnimationState}
                                  variants={windowTextAnimationVariants}
                                  className='window-text-lit'>
                                  {id}
                                  </motion.div>
                                </div>
                                }                    
                                </motion.div>
                      }
                      
                      {bookVisibility && 
                           <Book content={content} minimizeBook={minimizeWindow}></Book>
                            
                      }

                      {opening && 
                            <motion.div 
                              initial="visible"
                              animate={windowCardAnimationState}
                              variants={windowCardAnimationVariants}
                              className={"card"}>
                                  <div className='window-text'>
                                      {id}
                                      </div>
                                      
                                      
                            </motion.div>
                      }

                      {!open && !opening &&
                            <motion.div 
                            
                                  onMouseOver={()=>onMouseHover(winID)}
                                  onMouseOut={()=>onMouseHoverStop()}
                            ref={ref} className={"card"} onClick={()=>attemptOpenWindow(openWindow,winID)}>
                                <div className='window-text'>
                                  {id}
                                </div>
                               
                            </motion.div>
                      }
      
  </MotionConfig>
)
}


export default Window;
