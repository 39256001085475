import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { gsap } from "gsap";


const Authentication = (props) => {
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [correctPIN, setCorrectPin] = useState(props.correctPIN);

  const variants = {
    wrong: {
      
    },
    right: {
      ease: "easeOut",
    }
  };
  useEffect(()=>
  {
    const input = document.querySelector(".global-pin-input");
    input.focus();
  })

  const handlePinChange = value => {

    if(value.length==5)
    return;

    setPinCode(value);

    if(value.length!=4)
    return;
    if(value===props.correctPIN)
    {
      props.onSuccess();
      var element = document.getElementsByClassName("pin-field");
      gsap.fromTo(element,{y:0},{y:"-20", duration: 0.3, ease:"easeIn"});
      gsap.fromTo(element,{y:-20, opacity: 1},{y:"250", opacity: 0, delay: 0.3, duration: 0.6, ease:"easeOut"});
      

    }
    else{
     // props.onFail();
      var element = document.getElementsByClassName("pin-field");
      
      
      gsap.fromTo(element,{x:0},{x:"+=20", duration: 0.1, ease:"easeIn"});
      gsap.fromTo(element,{x:20},{x:"-40", delay:0.1,duration: 0.1, ease:"easeIn"});
      gsap.fromTo(element,{x:-20},{x:"0", delay:0.2,duration: 0.1, ease:"easeIn"});
    }
  }
  return (
    
      <div className="pin-field">
        <div className="pin-area">
          {pinCode.length>0 &&
            <div className="pin-unit"></div>
          }
          
        </div>
        <div className="pin-area">
          {pinCode.length>1 &&
            <div className="pin-unit"></div>
          }
        </div>
        <div className="pin-area">
          {pinCode.length>2 &&
            <div className="pin-unit"></div>
          }
        </div>
        <div className="pin-area">
          {pinCode.length>3 &&
            <div className="pin-unit"></div>
          }
        </div>
        <input className="global-pin-input" value={pinCode}   type="password" onChange={e => handlePinChange(e.target.value)} autoFocus={true}></input>
        
      </div>
  )}


export default Authentication;
